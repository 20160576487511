import Collapse from '../../../node_modules/bootstrap/js/src/collapse';

document.addEventListener('DOMContentLoaded', () => {
    const autohide = document.querySelector('.navbar.autohide');

    // add padding-top to body (if needed)
    var navbarHeight = document.querySelector('.navbar').offsetHeight;
    document.body.style.paddingTop = navbarHeight + 'px';

    if (autohide) {
        var lastScrollTop = window.scrollY + navbarHeight;
        var navTopOffset = 0;
        var navbarContent = document.querySelector('#navbar-content');
        var navbarCollapse = Collapse.getOrCreateInstance(navbarContent, {toggle: false});

        window.addEventListener('scroll', () => {
            if (window.innerWidth < 768) {

                let scrollTop = window.scrollY;
                
                const scrollAmount = Math.abs(scrollTop - lastScrollTop);
                const isScrollingDown = scrollTop > lastScrollTop;
                
                if (isScrollingDown) {
                    navTopOffset = Math.min(Math.max(navTopOffset - scrollAmount, -navbarHeight), 0);
                    if (navbarContent.classList.contains('show') && navTopOffset == -navbarHeight) {
                        navbarCollapse.hide();
                    }
                }
                else {
                    navTopOffset = Math.min(Math.max(navTopOffset + scrollAmount, -navbarHeight), 0);
                }
                
                autohide.style.top = scrollTop + navTopOffset + 'px';
                // console.log(autohide.style.top);
                // console.log(navTopOffset);
                lastScrollTop = scrollTop;
            } else if (autohide.style.top != '0px') {
                autohide.style.top = '0px';
            }
        });
    }
});